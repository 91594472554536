import React from 'react';
import { Chip } from '@mui/material';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import { styled } from '@mui/material/styles';

const label = {
  open: 'Otwarte',
  completed: 'Zakończone',
  closed: 'Zamknięte',
};

const StyledChip = styled(Chip)(({theme}) => ({
  "&.open": {
    backgroundColor: grey[200],
  },
  "&.completed": {
    backgroundColor: blue[200],
  },
  "&.closed": {
    backgroundColor: green[200],
  },
}));

const SettlementStatusChip = ({ status }) => (
  <StyledChip
    label={label[status]}
    style={{ color: 'black', fontWeight: 600 }}
    className={status}
  />
);

export default SettlementStatusChip;
