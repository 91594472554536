import React from 'react';
import Layout from '../../../templates/layout';
import SettlementsList from '../../../components/user/settlements/SettlementsList';

const SettlementsPage = props => (
  <Layout>
    <SettlementsList />
  </Layout>
);

export const Head = () => <title>Rozliczenia | Zapłatomat</title>;

export default SettlementsPage;
