import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert
} from '@mui/material';
import Paginator from '../../Paginator';
import StyledTableCell from '../../utils/StyledTableCell';
import BasePageContent from '../../utils/BasePageContent';
import { navigate } from 'gatsby';
import SettlementStatusChip from '../../utils/SettlementStatusChip';
import formatAmount from '../../utils/formatAmount';
import { styled } from '@mui/material/styles';

const mapStateToProps = state => ({
  settlements: state.getSettlements,
});

const PREFIX = "SettlementsList";
const classes = {
  root: `${PREFIX}-root`,
  tableRow: `${PREFIX}-tableRow`,
}
const RootPaginator = styled(Paginator)(({theme}) => ({
  [`& .${classes.root}`]: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
  [`& .${classes.tableRow}`]: {
    cursor: 'pointer',
  },
}));

class SettlementsList extends Component {
  renderSettlementRow = settlement => {
    const { data } = settlement;

    return (
      <TableRow
        hover
        key={data.code}
        className={classes.tableRow}
        onClick={() => navigate(`/user/settlements/${data.code}`)}
      >
        <TableCell>{data.code}</TableCell>
        <TableCell>{data.updatedAt}</TableCell>
        <TableCell>{formatAmount(data.amount, data.currency)}</TableCell>
        <TableCell>{formatAmount(data.feeAmount, data.currency)}</TableCell>
        <TableCell>{<SettlementStatusChip status={data.status} />}</TableCell>
      </TableRow>
    );
  };

  render() {
    return (
      <RootPaginator
        resource={this.props.settlements}
        getResource={this.props.actions.getSettlements}
        render={(settlements, paginationFooter, loading, error) => (
          <BasePageContent loading={loading} title='Rozliczenia'>
            <Box textAlign='center'>
              {error ? (
                <Alert severity='error'>{error}</Alert>
              ) : (
                <>
                  {settlements?.length ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Kod</StyledTableCell>
                          <StyledTableCell>Zaktualizowano</StyledTableCell>
                          <StyledTableCell>Kwota</StyledTableCell>
                          <StyledTableCell>Opłata</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {settlements.map((settlement, idx) =>
                          this.renderSettlementRow(settlement, idx)
                        )}
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography>
                      Dotychczas nie utworzono żadnego rozliczenia
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <div className={classes.root}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item>{paginationFooter()}</Grid>
              </Grid>
            </div>
          </BasePageContent>
        )}
      />
  );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementsList);
